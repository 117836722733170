import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ContentServices";
import HeroSection from "../components/Home/HaderHome";
import Paletacolor from "../components/global/Paletacolor";
import BlockTwo from "../components/block/BlockOne";
import BlockThree from "../components/block/BlockThree";
import BlockOne from "../components/block/BlockFour";
import ValuesOne from "../components/values/ValuesOne";
import BlockThreeAbout from "../components/block/BlockThree";
import GalleryComponent from "../components/Gallery/Gallery";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="w-full mx-auto flex justify-center items-center">
        <img src={rpdata?.dbPrincipal?.logo} alt="logo" className="w-[20%]"/>
      </div>
      <HeroSection />
      <div className="md:max-w-full w-full">
        <Modal />
        <BlockOne
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.gallery?.[1]}
          image2={rpdata?.gallery?.[2]}
          image3="https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/year%2F28%20YEARS-01.png?alt=media&token=f05a5329-6e9b-4ee3-822f-74978ce81e38"
          sloganPrincipal={true}
          listServices={true}
        />
        <Directories />
        <CounterUp image={rpdata?.stock?.[5]} />
        <BlockTwo
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image={rpdata?.gallery?.[4]}
        />
        <BlockThree
          title={rpdata?.dbSlogan?.[4]?.slogan}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.gallery?.[6]}
          listsAbout={true}
        />
        <ValuesOne image={rpdata?.gallery?.[7]} />
        <BlockThreeAbout
          title={rpdata?.dbSlogan?.[2]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.gallery?.[5]}
        />
        <ServicesHome />
        <GalleryComponent/>
        {/* Paleta de Colores */}
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.gallery?.[0]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
